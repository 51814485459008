import styled from "styled-components";
import media from "styled-media-query";
import Div from "app/components/Div";
import { H1 } from "app/components/Typography";
import {
  XSMALL_BREAKPOINT,
  SMALL_BREAKPOINT,
  LARGE_BREAKPOINT,
} from "constants/index";

export const ContainerWrapper = styled(Div)`
  height: 100%;
  width: 100%;

  ${media.greaterThan("medium")`
    display: flex;
    justify-content: center;
  `}
`;

export const Container = styled(Div)`
  height: 100%;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.ltlg} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ProgressBar = styled(Div)`
  transition: height 300ms ease-in-out;

  ${media.lessThan(LARGE_BREAKPOINT)`
    transition: width 300ms ease-in-out;
  `}

  ${({ theme, color }) => `
    border-left: 4px solid ${theme.colors[color]};
  `}

  ${({ theme }) => theme.mediaQueries.ltlg} {
    border-left: none;

    ${({ theme, color }) => `
      border-bottom: 4px solid ${theme.colors[color]};
    `}
  }
`;

export const Title = styled(Div)`
  color: ${({ theme }) => theme.colors.black};
  line-height: 75%;
  font-weight: bold;
  font-size: 64px;
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaQueries.ltlg} {
    line-height: 110%;
    margin-bottom: 12px;
    margin-top: 16px;
  }

  ${({ theme }) => theme.mediaQueries.ltmd} {
    font-size: 40px;
  }

  ${media.lessThan(XSMALL_BREAKPOINT)`
    font-size: 32px;
  `}
`;

export const Subtitle = styled(H1)`
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 24px;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    font-size: 14px;
  }

  ${media.lessThan(SMALL_BREAKPOINT)`
    margin-bottom: 8px;
  `}
`;
