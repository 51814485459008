import React from "react";
import { AnimationControls, motion } from "framer-motion";
import { Title, Subtitle } from "../../styles";
import { EntryAnimationWrapper } from "../EntryAnimationWrapper";

interface WrapperProps {
  animationControls: AnimationControls;
  children: React.ReactNode;
}

export function CategoriesWrapper({
  animationControls,
  children,
}: WrapperProps) {
  return (
    <motion.div animate={animationControls}>
      <EntryAnimationWrapper>
        <Title>Dance Styles</Title>
        <Subtitle>Select all the styles you&#39;re interested in</Subtitle>
        {children}
      </EntryAnimationWrapper>
    </motion.div>
  );
}
