import React from "react";
import { motion } from "framer-motion";

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
}

export function EntryAnimationWrapper({ children, disabled = false }: Props) {
  return (
    <motion.div
      initial={disabled ? false : { y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}
